<template>
  <div>
    <div class="text-right">
      <v-btn
        @click="copy(code)"
        class="text-transform-none dark-grey-text"
        depressed
      >
        <v-icon v-text="'mdi-content-copy'" left></v-icon>
        Copy this code
      </v-btn>
    </div>
    <prism-editor
      class="my-editor"
      v-model="code"
      readonly
      :highlight="highlighter"
      line-numbers
    ></prism-editor>
  </div>
</template>

<script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";

export default {
  components: {
    PrismEditor
  },
  props: {
    code: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.js); //returns html
    },
    async copy(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.notifyUserMessage({
          message: "Copied successfully",
          timeout: true
        });
      } catch (error) {
        this.notifyErrorMessage({
          message: "An error occurred while copying",
          timeout: true
        });
      }
    }
  }
};
</script>

<style scoped>
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
